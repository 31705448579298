import { useMemo } from 'react';

import { useAuth } from '@context/auth';

import { getHost } from '@scripts/helpers';

import { APIClient } from '..';

export const useAuthApiClient = (url: string = `${getHost()}/api/v1/`) => {
    const { tokenData, setTokenData } = useAuth();

    return useMemo(() => new APIClient(url, tokenData, setTokenData), [url, tokenData, setTokenData]);
};
