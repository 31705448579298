import { useMutation, useQuery, useQueryClient } from 'react-query';

import { CommonResponse } from '@api/common/types';
import { useAuthApiClient } from '@api/hooks/useAuthApiClient';

import { DeliveryMethods } from '@scripts/enums';

import { FetchError } from '../index';
import { DeliveryCalculateFormData, DeliveryCalculateItem, RefundDeliveryCalculateFormData } from './types';
import { TIMEOUT } from '@scripts/constants';

const API_URL = 'logistic/delivery/calculate';
const API_CONSOLIDATED_URL = 'logistic/delivery/calculate-consolidated';
export const LOGISTIC_KEY = 'calculate';
export const LOGISTIC_CONSOLIDATED_KEY = 'calculate-consolidated';

export const useDeliveryMethods = (data: DeliveryCalculateFormData, enabled = true) => {
    const apiClient = useAuthApiClient();
    return useQuery<CommonResponse<DeliveryCalculateItem>, FetchError>({
        queryKey: [LOGISTIC_KEY, data],
        queryFn: () => apiClient.post(API_URL, { data, timeout: TIMEOUT }),
        enabled,
        retry: 2,
    });
};

export const useDeliveryConsolidatedMethods = (data: Omit<DeliveryCalculateFormData, 'store_id'>, enabled = true) => {
    const apiClient = useAuthApiClient();
    return useQuery<CommonResponse<DeliveryCalculateItem>, FetchError>({
        queryKey: [LOGISTIC_CONSOLIDATED_KEY, data],
        queryFn: () => apiClient.post(API_CONSOLIDATED_URL, { data, timeout: TIMEOUT * 2 }),
        enabled,
        retry: 2,
    });
};

export const useDeliveryRefundMethods = (data: RefundDeliveryCalculateFormData, enabled: boolean) => {
    const apiClient = useAuthApiClient();
    return useQuery<CommonResponse<DeliveryCalculateItem>, FetchError>({
        queryKey: [LOGISTIC_KEY, data],
        queryFn: () => apiClient.post(API_URL, { data, timeout: TIMEOUT }),
        enabled,
    });
};

export const useDeliveryRefundMethodsMutate = () => {
    const queryClient = useQueryClient();
    const apiClient = useAuthApiClient();
    return useMutation<CommonResponse<DeliveryCalculateItem>, FetchError, RefundDeliveryCalculateFormData>(
        async data => {
            const response = await apiClient.post(API_URL, { data, timeout: TIMEOUT });
            return response.data;
        },
        {
            mutationKey: 'calcRequestKey',
            onMutate: async variables => {
                // Отменить предыдущий запрос
                // @ts-ignore
                await queryClient.cancelMutations(['calcRequestKey']);

                // Возвращаем данные, которые будут доступны в onSettled
                return { variables };
            },
            onSuccess: data => {
                queryClient.setQueryData([LOGISTIC_KEY, data], data);
            },
        }
    );
};

// Функция проверки успеха для различных типов доставки
export const isDeliveryParametersSuccess = (
    type: string | undefined,
    companyPVZ: string | null,
    pickUpPointId: string | null,
    companyCourier: string | null
): boolean => {
    switch (type) {
        case String(DeliveryMethods.PICKUP):
            return Boolean(companyPVZ && pickUpPointId);
        case String(DeliveryMethods.DELIVERY):
            return Boolean(companyCourier);
        case String(DeliveryMethods.PICKUP_STORE):
            return true;
        default:
            return false;
    }
};
