import { useCallback, useMemo } from 'react';
import { QueryClient } from 'react-query';

import { useBasketAddProduct, useGetBasket } from '@api/basket';
import { ProductDetail } from '@api/catalog';
import { getProductDetail } from '@api/catalog/product/api';
import { CommonResponse } from '@api/common/types';
import { useAuthApiClient } from '@api/hooks/useAuthApiClient';

import { useCartContext } from '@context/cart';

import { fromKopecksToRouble, getItemForMetrics, metricsPushEvent } from '@scripts/helpers';

import { useGetUserData } from './useGetUserData';

export const useAddToBasketHandler = () => {
    const { user: userToken } = useGetUserData();

    const apiClient = useAuthApiClient();

    const { basketProducts, setBasketProducts } = useCartContext();
    const { mutateAsync: addProduct } = useBasketAddProduct();

    const { data: apiBasketData } = useGetBasket(Boolean(userToken));
    const basketData = useMemo(() => apiBasketData?.data || [], [apiBasketData]);

    return useCallback(
        async (id: number, product?: ProductDetail, setIsLoadingBasket?: (val: boolean) => void) => {
            const user = userToken;

            const currentBasketItem = user
                ? basketData.find(item => item.id === id)
                : basketProducts.find(item => item.id === id);

            const quantityProduct = () => {
                if (!product) return 1;

                switch (true) {
                    case fromKopecksToRouble(product?.multiple) > product?.quantity:
                        return product?.quantity;
                    case !!product:
                        return fromKopecksToRouble(product?.multiple);
                    default:
                        return 1;
                }
            };

            const queryClient = new QueryClient();

            const { key: baseProductKey, fetch: baseProductFetch } = getProductDetail(apiClient)({
                id,
                include: 'brand,images,category,files,seller,attributes',
            });

            let apiProduct: CommonResponse<ProductDetail> | null = null;

            if (!product?.id) apiProduct = await queryClient.fetchQuery(baseProductKey, baseProductFetch);

            if (user) {
                if (setIsLoadingBasket) setIsLoadingBasket(true);

                if (currentBasketItem) {
                    await addProduct([{ offer_id: id, qty: currentBasketItem.qty + 1 }]);
                } else {
                    await addProduct([{ offer_id: id, qty: quantityProduct() }]);
                }
            } else {
                const newProducts = [...basketProducts];

                if (currentBasketItem) {
                    setBasketProducts(
                        newProducts?.map(item => (item.id === id ? { ...item, qty: quantityProduct() } : item))
                    );
                    return;
                }

                if (apiProduct?.data) newProducts.push({ ...apiProduct?.data, qty: quantityProduct() });
                if (product) newProducts.push({ ...product, qty: quantityProduct() });

                setBasketProducts([...newProducts]);
            }

            metricsPushEvent({
                event: 'add_to_cart',
                ecommerce: {
                    items: [
                        {
                            ...getItemForMetrics(apiProduct?.data || product),
                            quantity: currentBasketItem ? currentBasketItem.qty + 1 : 1,
                        },
                    ],
                },
            });
        },
        [basketData, basketProducts]
    );
};
