import { useMemo } from 'react';

import { useCurrentUser } from '@api/auth';

import { useAuth } from '@context/auth';

export const useGetUserData = () => {
    const { isAuthUser } = useAuth();
    const { data: apiUser, isIdle, error, isLoading } = useCurrentUser(isAuthUser);
    const userData = useMemo(() => apiUser?.data, [apiUser]);

    return { user: isAuthUser, apiUser, userData, isIdle, error, isLoading };
};
