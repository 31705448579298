import Link from 'next/link';
import { useRouter } from 'next/router';
import { useCatalogStore } from 'src/store';
import { shallow } from 'zustand/shallow';

import { Button, scale } from '@scripts/gds';

import MenuIcon from '@icons/24/system-menu.svg';
import LogoIcon from '@icons/market-logo.svg';
import CrossIcon from '@icons/white-cross.svg';

import CatalogPopup from './Catalog/CatalogPopup';
import { Search } from './Search';
import UserMenu from './UserMenu';

const CatalogButton = () => {
    const { isCatalogPopup, setIsCatalogPopup } = useCatalogStore(
        ({ isCatalogPopup, setIsCatalogPopup }) => ({
            isCatalogPopup,
            setIsCatalogPopup,
        }),
        shallow
    );

    return (
        <>
            <Button
                data-testid="button-catalog"
                theme={isCatalogPopup ? 'primary' : 'secondary'}
                Icon={isCatalogPopup ? CrossIcon : MenuIcon}
                size="md"
                css={{
                    marginRight: scale(2),
                }}
                onClick={() => setIsCatalogPopup(!isCatalogPopup)}
            >
                Каталог
            </Button>

            <CatalogPopup isOpen={isCatalogPopup} closeHandler={() => setIsCatalogPopup(false)} />
        </>
    );
};

const HeaderMainPartDesktop = ({ isSearchFixed }: { isSearchFixed: boolean }) => {
    const { push } = useRouter();

    const setIsCatalogPopup = useCatalogStore(({ setIsCatalogPopup }) => setIsCatalogPopup);

    return (
        <>
            <div
                id="HeaderMainPartDesktop"
                css={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                {!isSearchFixed ? (
                    <Link aria-label="home" onClick={() => setIsCatalogPopup(false)} href="/" passHref prefetch={false}>
                        <LogoIcon
                            css={{
                                marginRight: scale(3),
                            }}
                        />
                    </Link>
                ) : (
                    <div
                        css={{
                            flexGrow: 1,
                            margin: `0 ${scale(3)}px`,
                        }}
                    >
                        <Search
                            setIsCatalogPopup={() => setIsCatalogPopup(false)}
                            onSearch={val => {
                                const newParams = new URLSearchParams();
                                if (val.categoryId) {
                                    newParams.append('category_id', JSON.stringify(val.categoryId.toString()));
                                }

                                newParams.append('q', JSON.stringify(val.q));

                                push({
                                    pathname: '/catalog/search',
                                    query: newParams.toString(),
                                });
                            }}
                            placeholder="Поиск товаров в магазине"
                        />
                    </div>
                )}
            </div>

            <CatalogButton />

            <div
                css={{
                    flexGrow: 1,
                    marginRight: scale(4),
                }}
            >
                <Search
                    setIsCatalogPopup={() => setIsCatalogPopup(false)}
                    onSearch={val => {
                        const newParams = new URLSearchParams();
                        if (val.categoryId) {
                            newParams.append('category_id', JSON.stringify(val.categoryId.toString()));
                        }

                        newParams.append('q', JSON.stringify(val.q));

                        push({
                            pathname: '/catalog/search',
                            query: newParams.toString(),
                        });
                    }}
                    placeholder="Поиск товаров в магазине"
                />
            </div>

            <UserMenu />
        </>
    );
};

export default HeaderMainPartDesktop;
