import { Dispatch, useCallback } from 'react';
import { QueryClient, useQueryClient } from 'react-query';

import {
    BASKET_DELIVERIES_QUERY_KEY,
    BASKET_QUERY_KEY,
    useBasketAddProduct,
    useDeleteBasket,
    useGetBasket,
} from '@api/basket';
import { ProductDetail, QUERY_KEY_AUTH_OFFERS, QUERY_KEY_OFFERS } from '@api/catalog';
import { getPagination } from '@api/common/helpers';
import { CUSTOMER_PROFILE_KEY, CompareData, FAVORITE_KEY } from '@api/customers';
import { COMPARE_KEY } from '@api/customers/compare';

import { TokenDataProps, useAuth } from '@context/auth';
import { useCartContext } from '@context/cart';

import { CURRENT_USER_QUERY_KEY } from '.';
import { apiFront } from '..';
import { EMPTY_USER, EXPIRATION_TIME } from '@scripts/constants';

export interface LoginData {
    data: {
        access_token: string;
        refresh_token: string;
        expires_in: string;
    };
}

export async function logoutHelper(
    push: (val: string) => void,
    setUser: Dispatch<TokenDataProps>,
    setBasketProducts: (val: []) => void,
    setComparedProducts: (val: []) => void,
    setBasketProductsServices: (val: []) => void,
    queryClient: QueryClient
) {
    await apiFront.logOut();
    queryClient.setQueryData(CURRENT_USER_QUERY_KEY, null);

    queryClient.setQueryData(
        [
            COMPARE_KEY,
            {
                sort: 'id',
                pagination: getPagination(-1),
            },
        ],
        []
    );
    queryClient.setQueryData(
        [
            FAVORITE_KEY,
            {
                sort: 'id',
                pagination: getPagination(-1),
            },
        ],
        []
    );

    setUser(EMPTY_USER);
    setBasketProducts([]);
    setBasketProductsServices([]);
    setComparedProducts([]);
    push('/');
}

export const useLoginHelper = () => {
    const queryClient = useQueryClient();

    const { isAuthUser, setTokenData } = useAuth();
    const { setBasketProducts } = useCartContext();

    const { data: apiBasketData } = useGetBasket(Boolean(isAuthUser));

    const { mutateAsync: clearBasket } = useDeleteBasket();
    const { mutateAsync: setProducts } = useBasketAddProduct();

    const loginHelper = useCallback(
        async (
            logInData: {
                login: string;
                password: string;
            },
            setIsLoading: (val: boolean) => void,
            basketProducts: ProductDetail[]
        ) => {
            setIsLoading(true);

            try {
                const data = await apiFront.post('login', { data: logInData });

                setTokenData({ accessToken: data.data.accessToken, hasRefreshToken: Boolean(data.data.refreshToken), expiresAt: data.data.expiresAt });

                setIsLoading(false);

                queryClient.invalidateQueries(CUSTOMER_PROFILE_KEY);
                queryClient.invalidateQueries(CURRENT_USER_QUERY_KEY);
                queryClient.invalidateQueries(QUERY_KEY_OFFERS);
                queryClient.invalidateQueries(QUERY_KEY_AUTH_OFFERS);
                queryClient.invalidateQueries(FAVORITE_KEY);
                queryClient.invalidateQueries(COMPARE_KEY);
                queryClient.invalidateQueries(BASKET_QUERY_KEY);

                try {
                    const basketRes = await queryClient.fetchQuery(BASKET_QUERY_KEY, () =>
                        useGetBasket(!!isAuthUser)
                    );

                    if (!basketProducts.length) return;

                    if (basketRes && basketRes.data !== null) {
                        await clearBasket();
                    }

                    await setProducts(basketProducts.map(item => ({ offer_id: item.id, qty: item.qty })));
                    setBasketProducts([]);

                    queryClient.setQueryData(BASKET_DELIVERIES_QUERY_KEY, null);
                    queryClient.invalidateQueries(BASKET_DELIVERIES_QUERY_KEY);
                } catch (error: any) {
                    setBasketProducts([]);
                    throw new Error(error.message);
                }
            } catch (error: any) {
                setIsLoading(false);

                throw new Error(error.message);
            }
        },
        []
    );

    const loginCodeByPhoneHelper = useCallback(
        async (
            logInData: {
                phone: string;
                code: string;
            },
            setIsLoading: (val: boolean) => void,
            basketProducts: ProductDetail[]
        ) => {
            setIsLoading(true);

            try {
                const data = await apiFront.post('login', { data: { loginType: 'login:by-phone', ...logInData } });

                setTokenData({ accessToken: data.data.accessToken, hasRefreshToken: Boolean(data.data.refreshToken), expiresAt: data.data.expiresAt });
                setIsLoading(false);

                queryClient.invalidateQueries(CUSTOMER_PROFILE_KEY);
                queryClient.invalidateQueries(CURRENT_USER_QUERY_KEY);
                queryClient.invalidateQueries(QUERY_KEY_OFFERS);
                queryClient.invalidateQueries(QUERY_KEY_AUTH_OFFERS);
                queryClient.invalidateQueries(FAVORITE_KEY);
                queryClient.invalidateQueries(COMPARE_KEY);
                queryClient.invalidateQueries(BASKET_QUERY_KEY);

                try {
                    if (!basketProducts.length) return;

                    if (apiBasketData && apiBasketData.data !== null) {
                        await clearBasket();
                    }

                    await setProducts(basketProducts.map(item => ({ offer_id: item.id, qty: item.qty })));
                    setBasketProducts([]);

                    queryClient.setQueryData(BASKET_DELIVERIES_QUERY_KEY, null);
                    queryClient.invalidateQueries(BASKET_DELIVERIES_QUERY_KEY);
                } catch (error: any) {
                    setBasketProducts([]);
                    throw new Error(error.message);
                }
            } catch (error: any) {
                setIsLoading(false);

                throw new Error(error.message);
            }
        },
        []
    );

    const loginCodeByEmailHelper = useCallback(
        async (
            logInData: {
                email: string;
                code: string;
            },
            setIsLoading: (val: boolean) => void,
            basketProducts: ProductDetail[]
        ) => {
            setIsLoading(true);

            try {
                const data = await apiFront.post('login', { data: { loginType: 'login:by-email', ...logInData } });

                setTokenData({ accessToken: data.data.accessToken, hasRefreshToken: Boolean(data.data.refreshToken), expiresAt: data.data.expiresAt });
                setIsLoading(false);

                queryClient.invalidateQueries(CUSTOMER_PROFILE_KEY);
                queryClient.invalidateQueries(CURRENT_USER_QUERY_KEY);
                queryClient.invalidateQueries(QUERY_KEY_OFFERS);
                queryClient.invalidateQueries(QUERY_KEY_AUTH_OFFERS);
                queryClient.invalidateQueries(FAVORITE_KEY);
                queryClient.invalidateQueries(COMPARE_KEY);
                queryClient.invalidateQueries(BASKET_QUERY_KEY);

                try {
                    if (!basketProducts.length) return;

                    if (apiBasketData && apiBasketData.data !== null) {
                        await clearBasket();
                    }

                    await setProducts(basketProducts.map(item => ({ offer_id: item.id, qty: item.qty })));
                    setBasketProducts([]);

                    queryClient.setQueryData(BASKET_DELIVERIES_QUERY_KEY, null);
                    queryClient.invalidateQueries(BASKET_DELIVERIES_QUERY_KEY);
                } catch (error: any) {
                    setBasketProducts([]);
                    throw new Error(error.message);
                }
            } catch (error: any) {
                setIsLoading(false);

                throw new Error(error.message);
            }
        },
        []
    );

    const loginByToken = useCallback(
        async (
            logInData: {
                token: string;
            },
            basketProducts: ProductDetail[]
        ) => {
            try {
                setTokenData({ accessToken: logInData.token, hasRefreshToken: false, expiresAt: EXPIRATION_TIME });

                queryClient.invalidateQueries(CUSTOMER_PROFILE_KEY);
                queryClient.invalidateQueries(CURRENT_USER_QUERY_KEY);
                queryClient.invalidateQueries(QUERY_KEY_OFFERS);
                queryClient.invalidateQueries(QUERY_KEY_AUTH_OFFERS);
                queryClient.invalidateQueries(FAVORITE_KEY);
                queryClient.invalidateQueries(COMPARE_KEY);
                queryClient.invalidateQueries(BASKET_QUERY_KEY);

                try {
                    if (!basketProducts.length) return;

                    if (apiBasketData && apiBasketData.data !== null) {
                        await clearBasket();
                    }

                    await setProducts(basketProducts.map(item => ({ offer_id: item.id, qty: item.qty })));
                    setBasketProducts([]);

                    queryClient.setQueryData(BASKET_DELIVERIES_QUERY_KEY, null);
                    queryClient.invalidateQueries(BASKET_DELIVERIES_QUERY_KEY);
                } catch (error: any) {
                    setBasketProducts([]);
                    throw new Error(error.message);
                }
            } catch (error: any) {
                throw new Error(error.message);
            }
        },
        []
    );

    const registerByCodeHelper = useCallback(
        async (
            logInData: {
                email: string;
                code: string;
                phone: string;
                timezone: string;
                type: number;
                inn?: string;
                ogrn?: string;
                okato?: string;
                okfs?: string;
                okogu?: string;
                okpo?: string;
                oktmo?: string;
                okved?: string;
                org_name?: string;
                org_name_full?: string;
                org_info?: string;
            },
            setIsLoading: (val: boolean) => void,
            basketProducts: ProductDetail[]
        ) => {
            setIsLoading(true);

            try {
                if (typeof window.ym !== undefined && typeof window.ym === 'function') {
                    window.ym(92835880, 'reachGoal', 'new_registration');
                }

                const data = await apiFront.post('register', { data: logInData });

                setTokenData({ accessToken: data.data.accessToken, hasRefreshToken: Boolean(data.data.refreshToken), expiresAt: data.data.expiresAt });
                setIsLoading(false);

                queryClient.invalidateQueries(CUSTOMER_PROFILE_KEY);
                queryClient.invalidateQueries(CURRENT_USER_QUERY_KEY);
                queryClient.invalidateQueries(QUERY_KEY_OFFERS);
                queryClient.invalidateQueries(QUERY_KEY_AUTH_OFFERS);
                queryClient.invalidateQueries(FAVORITE_KEY);
                queryClient.invalidateQueries(COMPARE_KEY);
                queryClient.invalidateQueries(BASKET_QUERY_KEY);

                try {
                    if (!basketProducts.length) return;

                    if (apiBasketData && apiBasketData.data !== null) {
                        await clearBasket();
                    }

                    await setProducts(basketProducts.map(item => ({ offer_id: item.id, qty: item.qty })));
                    setBasketProducts([]);

                    queryClient.setQueryData(BASKET_DELIVERIES_QUERY_KEY, null);
                    queryClient.invalidateQueries(BASKET_DELIVERIES_QUERY_KEY);
                } catch (error: any) {
                    setBasketProducts([]);
                    throw new Error(error.message);
                }
            } catch (error: any) {
                setIsLoading(false);

                throw new Error(error.message);
            }
        },
        []
    );

    return { loginHelper, loginCodeByPhoneHelper, loginCodeByEmailHelper, registerByCodeHelper, loginByToken };
};

export const changeComparedOffers = async (
    comparedProducts: ProductDetail[],
    isUser: boolean,
    deleteCompare: (val: CompareData) => void,
    createCompare: (val: CompareData) => void,
    setComparedProducts: (val: ProductDetail[]) => void,
    id: number,
    comparedAuthProductsIdsData: CompareData[],
    productInfo: ProductDetail
) => {
    const newProducts = [...comparedProducts];

    const index = newProducts.findIndex(item => item.id === id);

    if (index >= 0) {
        newProducts.splice(index, 1);
        if (isUser) {
            setComparedProducts(newProducts);
            await deleteCompare({
                id: comparedAuthProductsIdsData.find(item => item.offer_id === id)?.id,
            });
        }
    } else {
        newProducts.push(productInfo);
        if (isUser) {
            await createCompare({
                offer_id: id,
            });
        }
    }

    if (!isUser) {
        setComparedProducts(newProducts);
    }
};
