import { CommonResponse, CommonSearchParams, FetchError } from '@api/common/types';
import { useBaseQuery } from '@api/hooks';

import { getContentPages } from './api';
import { PageDetail, PageFilters } from './types/pages';
import { useAuthApiClient } from '@api/hooks/useAuthApiClient';

export const useContentPages = (data: CommonSearchParams<Partial<PageFilters>>, enabled = true) => {
    const apiClient = useAuthApiClient();
    return useBaseQuery<CommonSearchParams<Partial<PageFilters>>, CommonResponse<PageDetail[]>, FetchError>({
        data,
        getMethod: params => getContentPages(apiClient)(params),
        enabled,
    });
};
