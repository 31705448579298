import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useCatalogStore, useHeaderStore, usePrepareCity } from 'src/store';
import { shallow } from 'zustand/shallow';

import { Button, colors, scale } from '@scripts/gds';
import { useMedia } from '@scripts/hooks';

import BurgerIcon from '@icons/24/burger-secondary.svg';
import MenuIcon from '@icons/24/system-menu.svg';
import LogoIcon from '@icons/market-logo.svg';
import CrossIcon from '@icons/white-cross.svg';

import CartBlock from './CartBlock';
import { Search } from './Search';

const MobileMenuPopup = dynamic(() => import('@components/MobileMenuPopup'), { ssr: false });
const CatalogPopup = dynamic(() => import('./Catalog/CatalogPopup'), { ssr: false });
const CityPopup = dynamic(() => import('@components/CityPopup'), { ssr: false });
const GeoPositionPopup = dynamic(() => import('@components/GeoPosition'), { ssr: false });

const CatalogButton = () => {
    const { pathname } = useRouter();

    const { isCatalogPopup, setIsCatalogPopup } = useCatalogStore(
        ({ isCatalogPopup, setIsCatalogPopup }) => ({
            isCatalogPopup,
            setIsCatalogPopup,
        }),
        shallow
    );
    const isFixed = useHeaderStore(({ isFixed }) => isFixed);

    return (
        <>
            <Button
                data-testid="button-catalog"
                theme={isCatalogPopup ? 'primary' : 'secondary'}
                Icon={isCatalogPopup ? CrossIcon : MenuIcon}
                size="md"
                css={{ display: pathname === '/' && !isFixed ? 'inline-flex' : 'none !important', width: '100%' }}
                onClick={() => setIsCatalogPopup(!isCatalogPopup)}
            >
                Каталог
            </Button>
            <CatalogPopup isOpen={isCatalogPopup} closeHandler={() => setIsCatalogPopup(false)} />
        </>
    );
};

const HeaderMainPartMobile = ({ isSearchFixed }: { isSearchFixed: boolean }) => {
    const { push } = useRouter();
    const [isGeoPositionPopup, setIsGeoPostionPopup] = useState(true);
    const [isCityPopup, setIsCityPopup] = useState(false);
    const { lgMin, smMin, xxxsMin, xlMin, xxlMin } = useMedia();

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const setIsCatalogPopup = useCatalogStore(({ setIsCatalogPopup }) => setIsCatalogPopup);

    const { isFirstTime } = usePrepareCity();

    return (
        <>
            <div
                css={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: colors.white,
                    zIndex: 11,
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: 0,
                    [xxxsMin]: {
                        padding: `${scale(3)}px ${scale(3, true)}px ${scale(2)}px`,
                    },
                    [smMin]: {
                        padding: `${scale(3)}px ${scale(4)}px ${scale(2)}px`,
                    },
                    [lgMin]: {
                        padding: `${scale(3)}px  ${scale(3)}px  ${scale(2)}px`,
                    },
                    [xlMin]: {
                        padding: `${scale(3)}px  ${scale(9)}px  ${scale(2)}px`,
                    },
                    [xxlMin]: {
                        padding: `${scale(3)}px  ${scale(10)}px ${scale(2)}px `,
                    },
                }}
            >
                <Button
                    theme="transparent"
                    onClick={() => setIsMenuOpen(true)}
                    aria-label="menu"
                    data-testid="button-burger"
                >
                    <BurgerIcon />
                </Button>

                <MobileMenuPopup isOpen={isMenuOpen} closeHandler={() => setIsMenuOpen(false)} />

                {!isSearchFixed ? (
                    <Link aria-label="home" onClick={() => setIsCatalogPopup(false)} href="/" passHref prefetch={false}>
                        <LogoIcon css={{ width: scale(27) }} />
                    </Link>
                ) : (
                    <div css={{ flexGrow: 1, margin: `0 ${scale(3)}px` }}>
                        <Search
                            setIsCatalogPopup={() => setIsCatalogPopup(false)}
                            onSearch={val => {
                                const newParams = new URLSearchParams();
                                if (val.categoryId) {
                                    newParams.append('category_id', JSON.stringify(val.categoryId.toString()));
                                }

                                newParams.append('q', JSON.stringify(val.q));

                                push({
                                    pathname: '/catalog/search',
                                    query: newParams.toString(),
                                });
                            }}
                            placeholder="Поиск товаров в магазине"
                        />
                    </div>
                )}

                <CartBlock />
            </div>

            <div css={{ flexGrow: 1 }}>
                <Search
                    setIsCatalogPopup={() => setIsCatalogPopup(false)}
                    onSearch={val => {
                        const newParams = new URLSearchParams();
                        if (val.categoryId) {
                            newParams.append('category_id', JSON.stringify(val.categoryId.toString()));
                        }

                        newParams.append('q', JSON.stringify(val.q));

                        push({
                            pathname: '/catalog/search',
                            query: newParams.toString(),
                        });
                    }}
                    placeholder="Поиск товаров в магазине"
                />
            </div>

            <CatalogButton />
            {isGeoPositionPopup && isFirstTime && (
                <GeoPositionPopup
                    isOpen={isGeoPositionPopup}
                    onChangeToDefineCity={() => {
                        setIsGeoPostionPopup(false);
                        setIsCityPopup(true);
                    }}
                    onClose={() => {
                        setIsGeoPostionPopup(false);
                    }}
                />
            )}
            <CityPopup isOpen={isCityPopup} closeHandler={() => setIsCityPopup(false)} />
        </>
    );
};

export default HeaderMainPartMobile;
