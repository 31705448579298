import { useRouter } from 'next/router';
import { FC, useRef } from 'react';
import Media from 'react-media';
import { useHeaderStore } from 'src/store';

import { Container, colors, scale } from '@scripts/gds';
import { useMedia } from '@scripts/hooks';

import HeaderMainPart from './HeaderMainPart';
import HeaderOptions from './HeaderOptions';

const MainHeader: FC = () => {
    const { pathname, query } = useRouter();
    const defaultMatches = query?.viewport ? query.viewport !== 'desktop' : false;

    const { lg } = useMedia();

    const isFixed = useHeaderStore(({ isFixed }) => isFixed);

    const headerRef = useRef<HTMLDivElement>(null);
    return (
        <div
            id="MainHeader"
            css={{
                height: pathname === '/' ? '312px' : '',
                marginTop: scale(3),
                width: '100%',
                [lg]: { marginTop: 0 },
            }}
        >
            <Media query={{ maxWidth: 1279 }} defaultMatches={defaultMatches}>
                {matches => (
                    <>
                        {isFixed && (
                            <div
                                css={{
                                    height:
                                        matches && headerRef?.current
                                            ? `${headerRef.current.clientHeight}px`
                                            : scale(9),
                                }}
                            />
                        )}

                        <div
                            id="MainHeaderMatches"
                            ref={headerRef}
                            css={{
                                ...(isFixed && {
                                    position: 'fixed',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    backgroundColor: colors.white,
                                    zIndex: 11,
                                    padding: `${scale(3)}px 0`,
                                }),
                            }}
                        >
                            <Container
                                id="MainHeaderMatchesContainer"
                                css={{
                                    display: 'flex',
                                    marginBottom: isFixed ? 0 : scale(3),
                                    [lg]: { flexDirection: 'column' },
                                }}
                            >
                                <HeaderMainPart matches={matches} />
                            </Container>
                        </div>

                        {(!matches || pathname === '/') && <HeaderOptions />}
                    </>
                )}
            </Media>
        </div>
    );
};

export default MainHeader;
