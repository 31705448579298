import { CommonSearchParams } from '@api/common/types';
import { APIClient } from '@api/index';

import { ProductFilter } from '../types';

const API_URL = 'catalog/categories';
const QUERY_KEY_CATEGORIES = 'categories';
const QUERY_KEY_CATEGORY = 'category';
const QUERY_KEY_CATEGORIES_TREE = `${QUERY_KEY_CATEGORIES}-tree`;

export const getCategoriesTree = (apiClient: APIClient) => ({
    key: [QUERY_KEY_CATEGORIES_TREE],
    fetch: () => apiClient.get(API_URL),
});

export const getCategoryDetail = (apiClient: APIClient) => (filter: {
    id?: number;
    code?: string;
    offers_filter?: CommonSearchParams<Partial<ProductFilter>>;
}) => ({
    key: [QUERY_KEY_CATEGORY, filter],
    fetch: () => apiClient.post(`${API_URL}:search-info`, { data: { filter } }),
});

export const getCategories = (apiClient: APIClient) => (data: CommonSearchParams<Record<string, boolean>>) => ({
    key: [QUERY_KEY_CATEGORIES, data],
    fetch: () => apiClient.post(`${API_URL}:search`, { data }),
});

export const getVirtualCategoryDetail = (apiClient: APIClient) => (data: { filter: { id?: number; code?: string }; include?: string[] }) => ({
    key: [QUERY_KEY_CATEGORY, data],
    fetch: () => apiClient.post(`catalog/virtual-categories:search-one`, { data }),
});
