import { GetServerSidePropsContext, GetServerSidePropsResult } from 'next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { dehydrate } from 'react-query';
import { useCityStore } from 'src/store';

import { useLoginHelper } from '@api/auth/helpers';
import { getCategories } from '@api/catalog/categories/api';
import { getProducts } from '@api/catalog/product/api';
import { getPagination } from '@api/common/helpers';
import { getBanners } from '@api/content/api';
import { getSeoTags } from '@api/seo/api';

import { useCartContext } from '@context/cart';
import { useSeoContext } from '@context/seo';

import { ExpandableBlock } from '@components/ExpandableBlock';
import PageWrapper from '@components/PageWrapper';
import ProductCard from '@components/ProductCard';
import ProductCarousel from '@components/ProductCarousel';
import Carousel from '@components/controls/Carousel';
import Picture from '@components/controls/future/Picture';

import { BannerType } from '@scripts/enums/banners';
import { Layout, Section, colors, scale } from '@scripts/gds';
import { transformImageUrl } from '@scripts/helpers';
import { useMedia } from '@scripts/hooks';
import { useGetProductsList } from '@scripts/hooks/useGetProductsList';
import { useGetUserData } from '@scripts/hooks/useGetUserData';
import { getCommonProps } from '@scripts/server/getCommonProps';
import { ExtendedSSRMainPageProps, ISSRPageProps } from '@scripts/server/types';

import CarouselItem from './components/CarouselItem';
import MainBanners from './components/MainBanners';
import PopularCategoriesCarousel from './components/PopularCategoriesCarousel';
import { featuresButtons, searchParams } from './helper';
import { linkStyles } from './styles';

export default function Home({
    categoryBanner: categoryBannerData,
    bigBanner: bigBannerData,
    rightBanner: rightBannerData,
    sliderBanner: sliderBannderData,
    baseProductsProfit: baseProductsProfitData,
}: ExtendedSSRMainPageProps) {
    const { query } = useRouter();
    const { loginByToken } = useLoginHelper();

    const seoContexData = useSeoContext();

    const currentCity = useCityStore(({ currentCity }) => currentCity);
    const { basketProducts } = useCartContext();
    const { smMin, sm, xxxsMin, lgMin, xlMin, xxlMin } = useMedia();

    const { apiUser, error: userError, user } = useGetUserData();

    const { productsList: newProductsListData } = useGetProductsList(
        {
            ...searchParams,
            sort: '-created_at',
        },
        { enabled: !user, enabledAuth: Boolean(apiUser) && !userError }
    );

    const categoryBanner = categoryBannerData?.data[0];

    const bigBanner = bigBannerData?.data[0];

    const profitableProductsListData = baseProductsProfitData?.data;

    const { productsList: categoryProductsListData } = useGetProductsList(
        {
            ...searchParams,
            filter: { category_id: categoryBanner?.category_id },
        },
        {
            enabled: Boolean(!user && categoryBanner?.category_id),
            enabledAuth: Boolean(apiUser && !userError && categoryBanner?.category_id),
        }
    );

    useEffect(() => {
        if (query.access_token) {
            const res = async () => {
                await loginByToken(
                    { token: (query.access_token as string) || '' },
                    basketProducts
                );
            };

            res();

            window.history.pushState({}, document.title, '/');
        }
    }, [query]);

    return (
        <PageWrapper>
            <Section>
                <Layout cols={{ xxxl: 12, sm: 7 }} gap={{ xxxl: scale(8), sm: scale(5) }}>
                    <Layout.Item
                        col={{ xxxl: 12, sm: 7 }}
                        css={{
                            backgroundColor: colors.backgroundMain,
                            display: 'flex',
                            gap: scale(5),
                            flexDirection: 'column',
                            [xxxsMin]: {
                                margin: `0 -${scale(3, true)}px`,
                                padding: `${scale(3)}px ${scale(3, true)}px`,
                            },
                            [smMin]: {
                                margin: `0 -${scale(4)}px`,
                                padding: `${scale(3)}px ${scale(4)}px ${scale(4)}px`,
                            },
                            [lgMin]: {
                                margin: `0 -${scale(3)}px`,
                                padding: `${scale(3)}px  ${scale(3)}px ${scale(4)}px`,
                            },
                            [xlMin]: {
                                margin: `0 -${scale(9)}px`,
                                padding: `${scale(3)}px  ${scale(9)}px ${scale(4)}px`,
                            },
                            [xxlMin]: {
                                margin: 0,
                                padding: `${scale(3)}px  ${scale(10)}px ${scale(4)}px `,
                            },
                        }}
                    >
                        <MainBanners slider={sliderBannderData} right={rightBannerData} />

                        <div
                            css={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                gap: scale(3),
                                '.swiper-slide': {
                                    marginRight: '16px !important',
                                    width: '306px !important',
                                },
                                [sm]: {
                                    '.swiper-slide': {
                                        width: '284px !important',
                                    },
                                },
                            }}
                        >
                            <Carousel css={{ width: '100%' }} type="options">
                                {featuresButtons.map((item, index) => (
                                    <Carousel.Item key={item.id}>
                                        <CarouselItem item={item} idx={index} />
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </div>
                    </Layout.Item>

                    <Layout.Item col={{ xxxl: 12, sm: 7 }} css={{ width: '100%', height: '547px' }}>
                        <ProductCarousel
                            products={profitableProductsListData}
                            title="Выгодное предложение"
                            link={`/catalog/search?filters=%7B"is_profitable"%3A%5B"true"%5D%7D`}
                            lazy
                        />
                    </Layout.Item>

                    <Layout.Item col={{ xxxl: 12, sm: 7 }} css={{ width: '100%', height: '547px' }}>
                        <ProductCarousel
                            products={newProductsListData}
                            title="Новинки"
                            link="/catalog/search?is_new=true"
                            lazy
                        />
                    </Layout.Item>

                    {bigBanner?.url && (
                        <Layout.Item col={{ xxxl: 12, sm: 7 }} css={{ width: '100%', position: 'relative' }}>
                            <Link legacyBehavior href={bigBanner.url} passHref prefetch={false}>
                                <a css={{ width: '100%' }} aria-label={bigBanner.name}>
                                    <Picture
                                        sources={[
                                            {
                                                media: '(max-width: 567px)',
                                                image: transformImageUrl(
                                                    bigBanner.mobile_image || '',
                                                    0,
                                                    scale(40),
                                                    'we'
                                                ),
                                            },
                                            {
                                                media: '(max-width: 767px)',
                                                image: transformImageUrl(
                                                    bigBanner.tablet_image || '',
                                                    0,
                                                    scale(40),
                                                    'we'
                                                ),
                                            },
                                            {
                                                media: '(min-width: 768px)',
                                                image: transformImageUrl(
                                                    bigBanner.desktop_image || '',
                                                    0,
                                                    scale(40),
                                                    'we'
                                                ),
                                            },
                                        ]}
                                        loading="lazy"
                                        placeholder="blur"
                                        alt=""
                                        width="auto"
                                        height="auto"
                                        style={{
                                            objectFit: 'cover',
                                            objectPosition: 'left',
                                            width: '100%',
                                            height: '100%',
                                            borderRadius: scale(1),
                                            maxHeight: scale(40),
                                        }}
                                    />
                                </a>
                            </Link>
                        </Layout.Item>
                    )}

                    <Layout.Item col={{ xxxl: 12, sm: 7 }} css={{ width: '100%' }}>
                        <PopularCategoriesCarousel />
                    </Layout.Item>

                    <Layout.Item col={{ xxxl: 12, sm: 7 }}>
                        {categoryProductsListData && categoryBanner?.category_id && (
                            <Layout cols={{ xxxl: 6, md: 5, xs: 6 }}>
                                <Layout.Item
                                    col={{ xxxl: 2, md: 2, xs: 6 }}
                                    css={{
                                        width: '100%',
                                        height: '100%',
                                        position: 'relative',
                                        [sm]: { height: scale(40) },
                                    }}
                                >
                                    {categoryBanner && (
                                        <Link legacyBehavior href={categoryBanner.url || ''} passHref prefetch={false}>
                                            <Picture
                                                sources={[
                                                    {
                                                        media: '(max-width: 567px)',
                                                        image: transformImageUrl(
                                                            categoryBanner.mobile_image || '',
                                                            0,
                                                            scale(40),
                                                            'we'
                                                        ),
                                                    },
                                                    {
                                                        media: '(max-width: 767px)',
                                                        image: transformImageUrl(
                                                            categoryBanner.tablet_image || '',
                                                            0,
                                                            scale(40),
                                                            'we'
                                                        ),
                                                    },
                                                    {
                                                        media: '(min-width: 768px)',
                                                        image: transformImageUrl(
                                                            categoryBanner.desktop_image || '',
                                                            0,
                                                            scale(54),
                                                            'we'
                                                        ),
                                                    },
                                                ]}
                                                loading="lazy"
                                                placeholder="blur"
                                                alt={categoryBanner.name}
                                                width="auto"
                                                height="auto"
                                                style={{
                                                    objectFit: 'cover',
                                                    objectPosition: 'left center',
                                                    width: '100%',
                                                    height: '100%',
                                                }}
                                            />
                                        </Link>
                                    )}

                                    {categoryBanner?.url && categoryBanner?.button?.text && (
                                        <Link legacyBehavior href={categoryBanner.url} passHref prefetch={false}>
                                            <a
                                                aria-label={categoryBanner.button.text}
                                                css={{
                                                    ...linkStyles,
                                                    zIndex: 2,
                                                    color: colors.primary,
                                                    border: '1px solid transparent',
                                                }}
                                            >
                                                {categoryBanner.button.text}
                                            </a>
                                        </Link>
                                    )}
                                </Layout.Item>

                                <Layout.Item col={{ xxxl: 4, md: 3, xs: 6 }} css={{ width: '100%' }}>
                                    <Carousel
                                        spaceBetween={scale(3)}
                                        slidesPerView={4}
                                        css={{ width: '100%', paddingBottom: scale(1) }}
                                        type="category"
                                        lazy
                                    >
                                        {categoryProductsListData.map(item => (
                                            <Carousel.Item key={item.id}>
                                                <ProductCard isSlider productItem={item} />
                                            </Carousel.Item>
                                        ))}
                                    </Carousel>
                                </Layout.Item>
                            </Layout>
                        )}
                    </Layout.Item>

                    <Layout.Item col={{ xxxl: 12, sm: 7 }}>
                        {seoContexData.seo_text && (
                            <ExpandableBlock height={scale(15)}>
                                <p
                                    css={{ marginTop: scale(6) }}
                                    dangerouslySetInnerHTML={{ __html: seoContexData.seo_text }}
                                />
                            </ExpandableBlock>
                        )}
                    </Layout.Item>
                </Layout>
            </Section>
        </PageWrapper>
    );
}

export const getServerSideProps = async (
    context: GetServerSidePropsContext
): Promise<GetServerSidePropsResult<ISSRPageProps>> =>
    getCommonProps(context, async (props, queryClient, apiClient) => {
        const { key: baseProductsProfitKey, fetch: baseProductsProfitFetch } = getProducts(apiClient)({
            ...searchParams,
            filter: { is_profitable: true },
            include: ['brand', 'category', 'images', 'attributes', 'seller', 'discounts'],
        });

        const baseProductsProfit = await queryClient.fetchQuery({
            queryKey: baseProductsProfitKey,
            queryFn: baseProductsProfitFetch,
        });

        const { key: popularCategoriesKey, fetch: popularCategoriesFetch } = getCategories(apiClient)({
            pagination: getPagination(12),
            filter: { is_active: true, is_real_active: true, is_promo: true },
        });

        const { key: baseProductsNewKey, fetch: baseProductsNewFetch } = getProducts(apiClient)({
            ...searchParams,
            sort: '-created_at',
        });

        const { key: sliderKey, fetch: sliderFetch } = getBanners(apiClient)({
            filter: { type: BannerType.SLIDER, is_on_main_page: true },
            pagination: getPagination(-1),
            sort: 'display_order',
        });
        const { key: rightBannerKey, fetch: rightBannerFetch } = getBanners(apiClient)({
            filter: { type: BannerType.SQUARE, is_on_main_page: true },
            sort: 'display_order',
        });
        const { key: bigBannerKey, fetch: bigBannerFetch } = getBanners(apiClient)({
            filter: { type: BannerType.BIG, is_on_main_page: true },
            sort: 'display_order',
        });
        const { key: categoryBannerKey, fetch: categoryBannerFetch } = getBanners(apiClient)({
            filter: { type: BannerType.PROMO, is_on_main_page: true },
            sort: 'display_order',
        });

        const categoryBanner = await queryClient.fetchQuery({
            queryKey: categoryBannerKey,
            queryFn: categoryBannerFetch,
        });

        const bigBanner = await queryClient.fetchQuery({
            queryKey: bigBannerKey,
            queryFn: bigBannerFetch,
        });

        const rightBanner = await queryClient.fetchQuery({
            queryKey: rightBannerKey,
            queryFn: rightBannerFetch,
        });

        const sliderBanner = await queryClient.fetchQuery({
            queryKey: sliderKey,
            queryFn: sliderFetch,
        });

        if (categoryBanner.data[0]?.category_id) {
            const { key: baseProductsCategoryKey, fetch: baseProductsCategoryFetch } = getProducts(apiClient)({
                ...searchParams,
                filter: { category_id: categoryBanner.data[0].category_id },
            });
            await queryClient.prefetchQuery(baseProductsCategoryKey, baseProductsCategoryFetch);
        }

        const { key: seoTagsKey, fetch: baseSeoTagsFetch } = getSeoTags(apiClient)({
            filter: {
                url: '/',
                type: 'main_page',
            },
        });
        await Promise.all([
            queryClient.prefetchQuery(baseProductsNewKey, baseProductsNewFetch),
            queryClient.prefetchQuery(seoTagsKey, baseSeoTagsFetch),
            queryClient.prefetchQuery(popularCategoriesKey, popularCategoriesFetch),
        ]);
        return {
            props: {
                ...props,
                categoryBanner: categoryBanner,
                bigBanner: bigBanner,
                rightBanner: rightBanner,
                sliderBanner: sliderBanner,
                baseProductsProfit: baseProductsProfit,
                dehydratedState: dehydrate(queryClient),
            },
        };
    });
