import dynamic from 'next/dynamic';
import { useMemo, useState } from 'react';

import { useGetBasket } from '@api/basket';

import { useAuth } from '@context/auth';
import { useCartContext } from '@context/cart';

import Badge from '@components/controls/Badge';

import { YANDEX_COUNTER_ID } from '@scripts/data/different';
import { STATUSES } from '@scripts/enums';
import { Button, colors, scale, typography } from '@scripts/gds';
import { useMedia } from '@scripts/hooks';

import CartIcon from '@icons/24/shop.svg';

const AuthPopup = dynamic(() => import('@components/NewAuth'), { ssr: false });
const CartPopup = dynamic(() => import('../CartPopup'), { ssr: false });

const CartBlock = () => {
    const { lgMin } = useMedia();

    const [isCartOpen, setIsCartOpen] = useState(false);
    const [isAuthPopup, setIsAuthPopup] = useState(false);

    const { isAuthUser } = useAuth();

    const { data: apiBasketData } = useGetBasket(isAuthUser);

    const basketData = useMemo(() => apiBasketData?.data || [], [apiBasketData]);
    const { basketProducts } = useCartContext();

    const numberOfProducts = useMemo(
        () => (isAuthUser ? basketData.length : basketProducts.length),
        [basketProducts, basketData, isAuthUser]
    );

    return (
        <>
            <Button
                data-testid="button-badge"
                theme="ghost"
                size="sm"
                onClick={() => {
                    setIsCartOpen(true);
                    if (typeof window.ym !== undefined && typeof window.ym === 'function') {
                        window.ym(YANDEX_COUNTER_ID, 'reachGoal', 'open_basket');
                    }
                }}
                aria-label="cart"
                css={{
                    flexDirection: 'column',
                    ...typography('bodyXSRegular'),
                    position: 'relative',
                    [lgMin]: { display: 'none !important' },
                }}
            >
                <CartIcon css={{ fill: colors.textMainBlack, marginBottom: scale(1, false, 10) }} />
                {numberOfProducts > 0 && <Badge text={String(numberOfProducts)} type={STATUSES.SUCCESS} />}
            </Button>

            <CartPopup
                isOpen={isCartOpen}
                closeHandler={() => setIsCartOpen(false)}
                openAuthPopup={() => {
                    setIsAuthPopup(true);
                }}
            />
            <AuthPopup isOpen={isAuthPopup} closeHandler={() => setIsAuthPopup(false)} />
        </>
    );
};

export default CartBlock;
