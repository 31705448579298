import { CommonResponse, CommonSearchParams, FetchError } from '@api/common/types';
import { useBaseQuery } from '@api/hooks';
import { Menu } from '@api/pages/types/menus';

import { getMenu } from './api';
import './types';
import { useAuthApiClient } from '@api/hooks/useAuthApiClient';

export const useMenu = (data: CommonSearchParams<Record<string, string>>) => {
    const apiClient = useAuthApiClient();
    return useBaseQuery<CommonSearchParams<Record<string, string>>, CommonResponse<Menu[]>, FetchError>({
        data,
        getMethod: params => getMenu(apiClient)(params),
    });
};