import { roboto } from '@styles/font';
import '@styles/globals.scss';
import type { AppProps } from 'next/app';
import getConfig from 'next/config';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { FC, useEffect, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';

import { useGetBasket } from '@api/basket';
import { useSeoTags } from '@api/seo/index';

import { useAuth } from '@context/auth';
import { useCartContext } from '@context/cart';
import { useCompareAndFavoriteContext } from '@context/compare';
import { SeoProvider } from '@context/seo';

import AppProviders from '@components/AppProviders';
import Footer from '@components/Footer';
import Header from '@components/Header';
import '@components/controls/Popup/styles.scss';

import { CustomersTypeEnum } from '@scripts/enums';
import { getIdBySeoType, getProductMaxQuantity, getSeoFilter } from '@scripts/helpers';
import { useMount } from '@scripts/hooks';
import { useAddToBasketHandler } from '@scripts/hooks/useAddToBasketHandler';
import { useChangeQty } from '@scripts/hooks/useChangeQty';
import { useCompareHelper } from '@scripts/hooks/useCompareHelper';
import { useFavoriteHelper } from '@scripts/hooks/useFavoriteHelper';
import { useGetUserData } from '@scripts/hooks/useGetUserData';

const AuthPopup = dynamic(() => import('@components/NewAuth'), { ssr: false });

declare const window: Window & { dataLayer: Record<string, unknown>[]; digiLayer: Record<string, unknown> };

const pathExceptions = ['/cart', '/cart/services/[id]', '/cart/registration'];

const AppContent: FC<AppProps> = ({ Component, pageProps }) => {
    const { pathname, asPath, query } = useRouter();

    const { apiUser, user } = useGetUserData();

    const [_, setCookies] = useCookies(['currentCityId']);
    const [isAuthPopup, setIsAuthPopup] = useState(false);


    useEffect(() => {
        if (apiUser?.data) {
            if (apiUser.data.customer_type === CustomersTypeEnum.PHISICAL) {
                setCookies('currentCityId', 2, { path: '/' });
            } else {
                setCookies('currentCityId', 1, { path: '/' });
            }
        } else {
            setCookies('currentCityId', 2, { path: '/' });
        }
    }, [apiUser?.data]);

    const addToBasket = useAddToBasketHandler();
    const changeQtyHelper = useChangeQty();

    const { basketProducts } = useCartContext();
    const { data: apiBasketData } = useGetBasket(Boolean(user));
    const basketData = useMemo(() => apiBasketData?.data || [], [apiBasketData]);

    const { compareProductHandler } = useCompareHelper({});
    const { comparedProducts, favoriteProductsData } = useCompareAndFavoriteContext();
    const { favoriteHelper } = useFavoriteHelper({ setIsAuthPopup });

    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        window.digiLayer = window.digiLayer || {};

        window.digiLayer.addToCart = addToBasket;
        window.digiLayer.changeQtyHelper = changeQtyHelper;
        window.digiLayer.addToCompareList = compareProductHandler;
        window.digiLayer.comparedProducts = comparedProducts;
        window.digiLayer.favoriteHelper = favoriteHelper;
        window.digiLayer.favoriteProductsData = favoriteProductsData;
        window.digiLayer.getProductMaxQuantity = getProductMaxQuantity;

        if (apiUser?.data) {
            window.digiLayer.cartState = basketData;
        } else {
            window.digiLayer.cartState = basketProducts;
        }
    }, [apiUser, basketData, basketProducts, comparedProducts, favoriteProductsData]);

    const seoItemId = getIdBySeoType(asPath, query);
    const { data: seoQueryData } = useSeoTags(getSeoFilter(asPath, seoItemId), Boolean(seoItemId));

    const seo = useMemo(
        () => ({
            h1: seoQueryData?.data?.h1 || '',
            title: seoQueryData?.data?.title || '',
            description: seoQueryData?.data?.description || '',
            keywords: seoQueryData?.data?.keywords || '',
            seo_text: seoQueryData?.data?.seo_text || '',
            canonical: seoQueryData?.data?.canonical || '',
        }),
        [seoQueryData?.data]
    );

    const { publicRuntimeConfig } = getConfig();

    return (
        <SeoProvider seoContextData={seo}>
            <Head>
                <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
                <link rel="icon" type="image/x-icon" href="/favicon.ico" />
                <link rel="manifest" href="/site.webmanifest" />
                <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />

                <meta name="msapplication-TileColor" content="#da532c" />
                <meta name="google-site-verification" content="iYnzFFphZSv413HLRnT3S2wau_cJ3zoFzbxNcauMMwc" />
                <meta name="theme-color" content="#ffffff" />
                {/* this string is required according to Ensi license */}
                <meta name="generator" content="Ensi Platform" />
                {publicRuntimeConfig?.environment !== 'localhost' && (
                    <>
                        <link rel="preconnect" href="https://cdn.diginetica.net/" />
                        <script type="text/javascript">
                            {`var digiScript = document.createElement('script');
                                digiScript.src = '//cdn.diginetica.net/${publicRuntimeConfig?.dataSiteId}/client.js';
                                digiScript.defer = true;
                                digiScript.async = true;
                                document.head.appendChild(digiScript);`}
                        </script>
                    </>
                )}

                {publicRuntimeConfig?.environment === 'DEV' && <meta name="robots" content="noindex, nofollow" />}
                {publicRuntimeConfig?.environment !== 'localhost' && publicRuntimeConfig?.environment !== 'DEV' && (
                    <>
                        <script src="//code.jivo.ru/widget/C6i3RMq5RY" defer></script>
                        <script type="text/javascript">
                            {`(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
                        m[i].l=1*new Date();
                        for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
                        k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
                        (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

                        ym(92835880, "init", {
                                clickmap:true,
                                trackLinks:true,
                                accurateTrackBounce:true,
                                webvisor:true,
                                ecommerce:"dataLayer"
                        });`}
                        </script>

                        <noscript>
                            <div>
                                <Image
                                    src="https://mc.yandex.ru/watch/92835880"
                                    css={{ position: 'absolute', left: -9999 }}
                                    alt=""
                                    aria-hidden="true"
                                />
                            </div>
                        </noscript>
                    </>
                )}
            </Head>

            {!pathExceptions.includes(pathname) && <Header />}
            <Component {...pageProps} />
            {!pathExceptions.includes(pathname) && <Footer />}

            <AuthPopup isOpen={isAuthPopup} closeHandler={() => setIsAuthPopup(false)} />
        </SeoProvider>
    );
};

function MyApp(props: AppProps) {
    return (
        <>
            <AppProviders {...props}>
                <style jsx global>{`
                    body,
                    html {
                        font-family: ${roboto.style.fontFamily} !important;
                    }
                    input,
                    textarea,
                    button,
                    select {
                        font-family: ${roboto.style.fontFamily} !important;
                    }
                    a,
                    p,
                    section,
                    main,
                    div,
                    span,
                    h1,
                    h2,
                    h3 {
                        font-family: ${roboto.style.fontFamily} !important;
                    }
                `}</style>
                <AppContent {...props} />
            </AppProviders>
        </>
    );
}

export default MyApp;
